@use "../../../variables";

.game-container {
  border-radius: 15px;
  padding: 40px 30px 20px;

  &.color-one {
    background: transparent linear-gradient(219deg, #7f00ff 0%, #7f00ff26 100%)
      0% 0% no-repeat padding-box;
  }

  &.color-two {
    background: transparent linear-gradient(218deg, #9cc334 0%, #9cc33426 100%)
      0% 0% no-repeat padding-box;
  }

  &.color-three {
    background: transparent linear-gradient(219deg, #90073a 0%, #90073a26 100%)
      0% 0% no-repeat padding-box;
  }

  &.color-four {
    background: transparent linear-gradient(217deg, #ffd926 0%, #ffd92626 100%)
      0% 0% no-repeat padding-box;
  }

  &.color-five {
    background: transparent linear-gradient(219deg, #6c01ff 0%, #6c01ff26 100%)
      0% 0% no-repeat padding-box;
  }

  &.color-six {
    background: transparent linear-gradient(219deg, #42cf5a 0%, #42cf5a26 100%)
      0% 0% no-repeat padding-box;
  }

  .game-img {
    height: 200px;
  }

  .game-title {
    font-family: "Barlow ExtraBold";
    font-size: 35px;
    letter-spacing: 0.9px;
    color: #ffffff;
    text-transform: uppercase;
  }
}
