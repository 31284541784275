@use "../../../variables";

.pool-container {
  border-radius: 15px;
  padding: 30px;
  background: #09190b 0% 0% no-repeat padding-box;
  border: 1px solid #20702a;

  .pool-img {
    height: 70px;
  }

  .pool-title {
    font-family: "Barlow ExtraBold";
    font-size: 25px;
    letter-spacing: 1.5px;
    color: #ffffff;
    text-transform: capitalize;
  }

  .pool-desc {
    font-family: "Barlow Medium";
    font-size: 15px;
    letter-spacing: 0.5px;
    color: #ffffff;
  }

  .apr-box-data {
    color: variables.$secondary-color;
    font-size: 20px;
    font-weight: bold;
  }

  .apr-box-title {
    color: variables.$btn-primary-txt-color;
    font-size: 14px;
  }

  .pool-border {
    border-right: 1.5px solid white;
  }

  .pool-box-data {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .pool-box-title {
    color: variables.$btn-primary-txt-color;
    font-size: 14px;
  }

  .btn-pool {
    background-color: variables.$btn-primary-color;
    border-color: variables.$btn-primary-color;
    color: variables.$btn-primary-txt-color;
    border-radius: 18px;
    height: 45px;
    font-size: 17px;
    letter-spacing: 1px;
    font-family: "Barlow Bold";
    text-transform: capitalize;

    &:active,
    &:hover,
    &:focus-visible,
    &:first-child:active {
      color: variables.$btn-primary-txt-color;
      background-color: variables.$btn-primary-color;
      border-color: variables.$btn-primary-color;
    }
  }
}
