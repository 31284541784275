@use "../../variables";

.title-container {
  text-align: center;

  .title {
    font-family: "Barlow ExtraBold";
    letter-spacing: 2.3px;
    text-shadow: 10px 10px 20px #00000057;
    text-transform: uppercase;
    font-size: 65px;
    color: variables.$primary-color;

    &.secondary {
      color: variables.$secondary-color;
    }
  }
}

.banner-container {
  position: relative;
  background-color: #7f00ff;
  border-radius: 20px;
  height: 400px;

  .banner-img-box {
    height: 100%;
    position: relative;
    padding-top: 20px;
    left: 75px;

    .banner-img {
      height: 100%;
    }
  }

  .banner-txt {
    position: absolute;
    top: 20%;
    left: 50px;
  }

  .banner-title {
    font-family: "Barlow ExtraBold";
    letter-spacing: 1.05px;
    color: #ffffff;
    font-size: 35px;
    text-transform: uppercase;
  }

  .banner-description {
    font-family: "Barlow Medium";
    letter-spacing: 0.6px;
    font-size: 15px;
    color: #ffffff;
  }
}

.btn-banner {
  background-color: variables.$btn-secondary-color;
  border-color: variables.$btn-secondary-color;
  color: variables.$btn-primary-txt-color;
  border-radius: 18px;
  width: 70%;
  height: 45px;
  font-size: 17px;
  letter-spacing: 1px;
  font-family: "Barlow Bold";
  text-transform: capitalize;

  &:active,
  &:hover,
  &:focus-visible,
  &:first-child:active {
    color: variables.$btn-primary-txt-color;
    background-color: variables.$btn-secondary-color;
    border-color: variables.$btn-secondary-color;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .banner-container {
    height: 370px;

    .banner-img-box {
      height: 100%;
      padding-top: 20px;
      left: 0px;
      width: 75%;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .banner-container {
    height: 370px;

    .banner-img-box {
      height: 100%;
      padding-top: 20px;
      left: 30px;
      width: 80%;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .banner-container {
    height: 370px;

    .banner-img-box {
      height: 100%;
      padding-top: 20px;
      left: 30px;
      width: 75%;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .banner-container {
    height: 370px;

    .banner-img-box {
      height: 100%;
      padding-top: 20px;
      left: 30px;
      width: 75%;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .banner-container {
    height: 400px;

    .banner-img-box {
      height: 100%;
      padding-top: 20px;
      left: 75px;
      width: 50%;
    }
  }
}
