.pepino-background {
  background-image: url("../../assets/img/colision-pepi-bg.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.first-section {
  height: 100%;
  background: transparent linear-gradient(357deg, #000000 0%, #23652e85 100%) 0%
    0% no-repeat padding-box;
}
