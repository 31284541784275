@font-face {
  font-family: "Barlow Regular";
  src: url("./assets/fonts/Barlow-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow Medium";
  src: url("./assets/fonts/Barlow-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow Bold";
  src: url("./assets/fonts/Barlow-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow ExtraBold";
  src: url("./assets/fonts/Barlow-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow SemiBold";
  src: url("./assets/fonts/Barlow-SemiBold.ttf") format("truetype");
}

body {
  margin: 0;
  background-color: #000000;
}

a {
  text-decoration: none !important;
  color: white;
}

.underline {
  text-decoration: underline !important;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-start {
  display: flex;
  justify-content: start;
}

.justify-end {
  display: flex;
  justify-content: end;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.align-center {
  display: flex;
  align-items: center;
}

.grid-center {
  display: grid;
  align-items: center;
}

.grid-content-center {
  display: grid;
  align-content: center;
}

.pointer {
  cursor: pointer;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-80 {
  padding-bottom: 80px;
}

.plr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.plr-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.plr-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.plr-40 {
  padding-left: 40px;
  padding-right: 40px;
}
