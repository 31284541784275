@use "../../variables";

.footer {
  background-color: #152321;
  height: 100%;

  .footer-logo {
    height: 100px;
  }

  .footer-subsection {
    letter-spacing: 0.4px;
    font-size: 15px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .rrss-container {
    background: #9cc334;
    border-radius: 15px;
    height: 55px;
    width: 55px;

    .rrss-icon {
      height: 25px;
    }
  }

  .copyright {
    font-family: "Barlow Medium";
    letter-spacing: 0.45px;
    color: #ffffff;
    font-size: 14px;
  }
}

.footer-banner {
  background-image: url("../../assets/img/background-footer.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}

.footer-img {
  position: relative;
  background-image: url("../../assets/img/pepino2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 375px;
}

.btn-footer {
  background-color: variables.$btn-primary-color;
  border-color: variables.$btn-primary-color;
  color: variables.$btn-primary-txt-color;
  border-radius: 20px;
  height: 50px;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: "Barlow Bold";
  text-transform: capitalize;

  &:active,
  &:hover,
  &:focus-visible,
  &:first-child:active {
    color: variables.$btn-primary-txt-color;
    background-color: variables.$btn-primary-color;
    border-color: variables.$btn-primary-color;
  }
}
