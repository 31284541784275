@use "../../variables";

.title-container {
  text-align: center;

  .title {
    font-family: "Barlow ExtraBold";
    letter-spacing: 2.3px;
    text-shadow: 10px 10px 20px #00000057;
    text-transform: uppercase;
    font-size: 65px;
    color: variables.$primary-color;

    &.secondary {
      color: variables.$secondary-color;
    }
  }
}

.soon-box {
  background-color: #09190b;
  border-radius: 16px;
  height: 450px;

  .title {
    font-family: "Barlow SemiBold";
    letter-spacing: 1.8px;
    color: #9cc334;
    text-transform: uppercase;
    opacity: 0.32;
    font-size: 60px;
    text-align: center;
  }
}

.btn-dao {
  background-color: variables.$btn-primary-color;
  border-color: variables.$btn-primary-color;
  color: variables.$btn-primary-txt-color;
  border-radius: 20px;
  width: 70%;
  height: 50px;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: "Barlow Bold";
  text-transform: capitalize;

  &:active,
  &:hover,
  &:focus-visible,
  &:first-child:active {
    color: variables.$btn-primary-txt-color;
    background-color: variables.$btn-primary-color;
    border-color: variables.$btn-primary-color;
  }
}
