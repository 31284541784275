@use "../../variables";

.pepino-header {
  height: 180px;
}

.pepino-app {
  width: 100%;
}

.title-container {
  text-align: center;

  .subtitle {
    font-family: "Barlow ExtraBold";
    letter-spacing: 2.3px;
    text-shadow: 10px 10px 20px #00000057;
    text-transform: uppercase;
    font-size: 45px;
    color: variables.$primary-color;

    &.secondary {
      color: variables.$secondary-color;
    }
  }
}

.separator {
  height: 25px;
}

.description {
  font-family: "Barlow Medium";
  font-size: 18px;
  color: white;
  text-align: center;
}

.btn-start {
  background-color: variables.$btn-primary-color;
  border-color: variables.$btn-primary-color;
  color: variables.$btn-primary-txt-color;
  border-radius: 30px;
  width: 200px;
  height: 55px;
  font-size: 17px;
  letter-spacing: 1.2px;
  font-family: "Barlow Bold";
  text-transform: uppercase;

  &:active,
  &:hover,
  &:focus-visible,
  &:first-child:active {
    color: variables.$btn-primary-txt-color;
    background-color: variables.$btn-primary-color;
    border-color: variables.$btn-primary-color;
  }
}

.check-img {
  height: 100%;
}

.check-txt {
  color: white;
}

.check-border {
  border-left: 2px solid variables.$secondary-color;
  border-right: 2px solid variables.$secondary-color;
}

.feature-box {
  background: #09190b 0% 0% no-repeat padding-box;
  border: 1px solid #20702a;
  border-radius: 10px;
  padding: 25px;

  .box-image {
    height: 125px;
  }

  .title {
    font-family: "Barlow ExtraBold";
    font-size: 24px;
    letter-spacing: 1px;
    color: white;
  }

  .subtitle {
    font-family: "Barlow Regular";
    font-size: 14px;
    color: white;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .title-container .title {
    font-size: 40px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .title-container .title {
    font-size: 60px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .title-container .title {
    font-size: 65px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
