@use "../../variables";

.navbar-toggler {
 background-color: #CCFF1C;
}

.navbar-collapse {
  text-align: center;
}

.main-logo {
  margin-top: 10px;
  height: 75px;
}

.nav-link {
  font-family: "Barlow Medium";
  color: variables.$primary-color;
  text-transform: uppercase;
  font-size: 15px;
  margin: 0 10px;
  text-decoration: none !important;
}

.telegram-logo {
  width: 100%;
}

.btn-app {
  background-color: variables.$btn-primary-color;
  border-color: variables.$btn-primary-color;
  color: variables.$btn-primary-txt-color;
  border-radius: 10px;
  width: 170px;
  height: 40px;
  font-family: "Barlow Bold";
  text-transform: uppercase;
  white-space: nowrap;

  &:active,
  &:hover,
  &:focus-visible,
  &:first-child:active {
    color: variables.$btn-primary-txt-color;
    background-color: variables.$btn-primary-color;
    border-color: variables.$btn-primary-color;
  }
}

.btn-telegram {
  background-color: variables.$btn-secondary-color;
  border-color: variables.$btn-secondary-color;
  color: variables.$btn-secondary-txt-color;
  border-radius: 10px;
  width: 50px;
  height: 40px;
  font-family: "Barlow Bold";
  text-transform: uppercase;

  &:active,
  &:hover,
  &:focus-visible,
  &:first-child:active {
    color: variables.$btn-secondary-txt-color;
    background-color: variables.$btn-secondary-color;
    border-color: variables.$btn-secondary-color;
  }
}
